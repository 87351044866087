.middle {
  top: 70%;
  left: 70%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.bar {
  width: 10px;
  height: 70px;
  background: #fff;
  display: inline-block;
  transform-origin: bottom center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
  animation: loader 1.2s linear infinite;
}

.bar1 {
  animation-delay: 0.1s;
}

.bar2 {
  animation-delay: 0.2s;
}

.bar3 {
  animation-delay: 0.3s;
}

.bar4 {
  animation-delay: 0.4s;
}

.bar5 {
  animation-delay: 0.5s;
}

.bar6 {
  animation-delay: 0.6s;
}

.bar7 {
  animation-delay: 0.7s;
}

.bar8 {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
    background: ;
  }

  50% {
    transform: scaleY(1);
    background: yellow;
  }

  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}


body {
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #1E1E23;
  opacity: 1;
  font-family: 'work sans', sans serif;
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;
}

a:hover {
  opacity: 0.5;
}

ul {
  padding: 0;
  list-style-type: none;
}



.container {
  margin-top: 50px;
  Display: flex;
  justify-content: center;
  align-items: center;
}

.phone {
  width: 300px;
  height: 600px;
  Display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  border: 1px solid #36383F;
  border-radius: 20px;
  width: 94%;
  height: 91%;
  background-color: #F5F6FA;
  overflow: hidden;
}

nav {
  background-color: #1E1E23;
  height: 65px;
}


#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383F;
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 180px;
  height: 400px;
  box-shadow: 0 0 10px #85888C;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background-color: #F5F6FA;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked~ul {
  transform: none;
}


@font-face {
  font-family: "Avenir-Next";
  src: local("Avenir-Next"),
    url("../../fonts/Avenir-Next.ttc") format("opentype");
  /* font-weight: bold; */
}

#input {
  font-family: 'Avenir-Next';
}