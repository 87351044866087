@font-face {
  font-family: "PricedownBl";
  src: local("PricedownBl"),
    url("./fonts/pricedown/pricedown-bl.ttf") format("truetype");
  /* font-weight: bold; */
}


@font-face {
  font-family: "Syne-Bold";
  src: local("Syne-Bold"),
    url("./fonts/Syne-Bold.ttf") format("opentype");
  /* font-weight: bold; */
}


body {
  margin: 0;
  font-family: 'Poppins';
}

.Button {
  font-family: 'Poppins';
}